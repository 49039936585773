import { useState, useEffect } from "react";

import { Header } from "../../Entities/Header/Header";
import { Philosophy } from "../../Entities/BlockPhilosophy/Philosophy";
import { Atmosphere } from "../../Entities/BlockAtmosphere/Atmosphere";
import { AboutFood } from "../../Entities/BlockAboutFood/AboutFood";
import { MakingBeer } from "../../Entities/BlockMakingBeer/MakingBeer";
import { ByTryingBeer } from "../../Entities/BlockByTryingBeer/ByTryingBeer";
import { TakeItWithYou } from "../../Entities/BlockTakeItWithYou/BlockTakeItWithYou";
import { Footer } from "../../Entities/Footer/Footer";

// OpenPub
import { OpenPub } from "../../Widgets/OpenPub/OpenPub";

//popup
import { RentTab } from "../../Widgets/Popup/rentTab/RentTab";
//Modal
import { ModalBar } from "../../Widgets/Modal/ModalBar";
//burger
import { Burger } from "../../Widgets/Burger/Burger";

//styles
import styles from "../../../styles/layout/Home/Home.module.scss";

const Home = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openBurger, setOpenBurger] = useState(false);

  useEffect(() => {
    openPopup || openModal || openBurger
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [openPopup, openModal, openBurger]);

  return (
    <div className={styles.wrapper}>
      {openPopup ? <RentTab setOpenPopup={setOpenPopup} /> : <></>}
      {openModal ? <ModalBar setOpenModal={setOpenModal} /> : <></>}
      {openBurger ? (
        <Burger setOpenBurger={setOpenBurger} setOpenPopup={setOpenPopup} />
      ) : (
        <></>
      )}
      <div className={styles.photoHeader}>
        <img
          loading="lazy"
          decoding="async"
          // src="https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/image_989.png"
          // src="/img/Header/image 989.jpg"
          src="/img/Header/OpenPub.png"
        />
      </div>
      <div className={styles.container}>
        <OpenPub />
        {/* <Header setOpenPopup={setOpenPopup} setOpenBurger={setOpenBurger} /> */}
        {/* <div className={styles.contentHomeGap}>
          <Philosophy />
          <Atmosphere setOpenPopup={setOpenPopup} setOpenModal={setOpenModal} />
          <AboutFood />
          <MakingBeer />
          <ByTryingBeer />
          <TakeItWithYou />
          <Footer />
        </div> */}
      </div>
    </div>
  );
};

export { Home };
