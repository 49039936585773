import styles from "../../../styles/layout/Header/Header.module.scss";
import iconMaplog from "../../../assets/icon/header/Vector.png";

const OpenPub = () => {
  return (
    <div className={styles.container}>
      <div className={styles.blockTitle}>
        <div className={styles.logoBeerBird}>
          <img
            alt={"Isolation_Mode"}
            src={
              "/img/Header/Isolation_Mode.svg"
              // "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/isolation_mode.svg"
            }
          />
        </div>

        <div className={styles.blockTitelText}>
          <h1>ПТИЦЫ ЗАХМЕЛЕЛИ</h1>
          <h2>Пивоварня, безупречная кухня, уникальный вид</h2>
        </div>
      </div>
      <div className={styles.blockOpen}>
        <span className={styles.textOpenPub}>
          {"Скоро открытие".toLocaleUpperCase()}
        </span>
      </div>
      <div className={styles.blockMap}>
        <img src={iconMaplog} />
        <h1 className={styles.textMap}>Курорт Красная Поляна, 960 м</h1>
      </div>
    </div>
  );
};

export { OpenPub };
